<template>
  <div
    @keydown.right.prevent="nextClick"
    @keydown.left.prevent="prevClick"
  >
    <div class="d-flex justify-content-end">
      <div
        class="buy-now date-selection-file-upload"
        style="top: 17%; margin-right: -50px; bottom: 100%; z-index: 2 !important;"
      >
        <a
          id="popover-reactive-1"
          class="btn btn-danger"
          style="position: relative; box-shadow: 0 1px 0px -1px #262262 !important; background-color: #262262 !important; border-color: #262262 !important;"
        >
          {{ Datevalue }}
        </a>
        <b-popover
          ref="popover"
          target="popover-reactive-1"
          triggers="click"
          :show.sync="popoverShow"
          placement="auto"
          container="my-container"
        >
          <template v-slot:title>
            <div class="d-flex justify-content-between align-items-center">
              <span>Select Month and year</span>
              <b-button
                class="close"
                variant="transparent"
                aria-label="Close"
                @click="onClose"
              >
                <span
                  class="d-inline-block text-white"
                  aria-hidden="true"
                >&times;</span>
              </b-button>
            </div>
          </template>

          <div>
            <b-col md="12">
              <b-form-group>
                <h5>Date</h5>
                <flat-pickr
                  v-model="Datevalue"
                  class="form-control"
                  :state="Datevaluestate"
                />
              </b-form-group>
            </b-col>
          </div>
        </b-popover>
      </div>
    </div>
    <!-- <div v-if="View1">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >

        <b-tab
          v-for="ipainformation in IpaInformation"
          :key="ipainformation[0].ipaName"
          active
        >

          <template #title>
            <feather-icon
              icon="CpuIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold"> {{ ipainformation[0].ipaName }}</span>
          </template>
          <information-roaster />
        </b-tab>

      </b-tabs>
    </div> -->
    <div style="margin-top: 40px; ">
      <div
        class="buy-now nextclick"
        style="top: 50%; bottom: 50%; right: 5%; z-index: 2 !important;"
      >
        <b-button
          id="popover-reactive-1"
          v-focus
          active
          style="position: relative; box-shadow: 0 1px 0px -1px #262262 !important"
          class="btn-icon rounded-circle"
          @click.prevent="nextClick"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="15"
          />
        </b-button>
      </div>
      <div
        class="buy-now prevclick"
        style="top: 50%; bottom: 50%; left: 30%; z-index: 2 !important;"
      >
        <b-button
          id="popover-reactive-1"
          v-focus
          active
          style="position: relative; box-shadow: 0 1px 0px -1px #262262 !important"
          class="btn-icon rounded-circle"
          @click.prevent="prevClick"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="15"
          />
        </b-button>
      </div>
      <div
        v-for="ipainformation in getIpas"
        :key="ipainformation[0].ipaName"
        class="row"
      >
        <div class="col-md-2 col-12">
          <b-card
            style="height: 123px; margin-top: 45px;"
            class="My-Card"
          >
            <div class="d-flex justify-content-end">
              <feather-icon
                icon="SettingsIcon"
                size="18"
                style="cursor: pointer;"
                @click="$router.push({
                  name: 'roaster-configuration',
                  params: { ipaInfo: ipainformation[0] },
                })"
              />
            </div>
            <div style="justify-content: center; align-items: center; display: flex; margin-top: 15px;">
              <feather-icon
                icon="CpuIcon"
                size="18"
                class="mr-50"
              />
              {{ ipainformation[0].ipaName }}
            </div>
          </b-card>
        </div>
        <div class="col-md-10 col-12">
          <swiper-centered-slides-importer-2
            :ipainformation="ipainformation"
            :refresh-ipa-data="getCounts"
          />
        </div>
      </div>
    </div>
    <list-detail-modal />
    <list-analitycs />
    <table-list-detail-modal />
    <roaster-configuration-modal />
  </div>
</template>

<script>
import {
  BButton, BCard, BPopover, BCol,
  BFormGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import MixinGetIpas from '@core/mixins/roaster-importer/getIpas'
import TableListDetailModal from '@core/components/modals/lists/TableListDetailModal.vue'
import RoasterConfigurationModal from '@core/components/modals/lists/RoasterConfigurationModal.vue'
import { mapGetters } from 'vuex'
import SwiperCenteredSlidesImporter2 from '@core/components/swiper-slide/SwiperCenteredSlidesImporter2.vue'
import ListDetailModal from '@core/components/modals/lists/ListDetailModal.vue'
import ListAnalitycs from '@core/components/modals/lists/ListAnalitycs.vue'

export default {
  components: {
    BButton,
    SwiperCenteredSlidesImporter2,
    BCard,
    BPopover,
    flatPickr,
    BFormGroup,
    BCol,
    ListDetailModal,
    TableListDetailModal,
    RoasterConfigurationModal,
    ListAnalitycs,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },
  mixins: [MixinGetIpas],
  data() {
    return {
      IpaInformation: [],
      View1: false,
      View2: true,
      popoverShow: false,
      Datevalue: null,
      Datevalue2: null,
      Datevaluestate: null,
    }
  },
  computed: {
    ...mapGetters({
      getIpas: 'ipa-roaster-list/getIpas',
    }),
  },
  watch: {
    Datevalue(val) {
      if (val) {
        this.popoverShow = false
        const Month = moment(val).format('MM')
        const Year = moment(val).format('YYYY')
        this.$emit('select-swiper-index', Month, Year)
        if (this.Datevalue2 !== null) {
          if (this.Datevalue2 !== moment(this.Datevalue).format('YYYY')) {
            this.getCounts(this.Datevalue)
            this.Datevalue2 = moment(this.Datevalue).format('YYYY')
          }
        } else {
          this.Datevalue2 = moment(this.Datevalue).format('YYYY')
        }
      }
    },
  },
  mounted() {
    this.getDate()
    this.refreshIpas()
  },
  created() {
    this.$on('childtoparentnext', this.nextClick)
  },
  methods: {
    refreshIpas() {
      this.getCounts(this.Datevalue)
    },
    DiffView(num) {
      if (num === 1) {
        this.View1 = true
        this.View2 = false
      } else if (num === 2) {
        this.View1 = false
        this.View2 = true
      }
    },
    onClose() {
      this.popoverShow = false
    },
    nextClick() {
      this.$emit('next')
      this.Datevalue = moment(this.Datevalue).add(1, 'month').format('YYYY-MM-DD')
      if (this.Datevalue2 !== null) {
        if (this.Datevalue2 !== moment(this.Datevalue).format('YYYY')) {
          this.getCounts(this.Datevalue)
          this.Datevalue2 = moment(this.Datevalue).format('YYYY')
        }
      } else {
        this.Datevalue2 = moment(this.Datevalue).format('YYYY')
      }
    },
    prevClick() {
      this.$emit('prev')
      this.Datevalue = moment(this.Datevalue).subtract(1, 'month').format('YYYY-MM-DD')
      if (this.Datevalue2 !== null) {
        if (this.Datevalue2 !== moment(this.Datevalue).format('YYYY')) {
          this.getCounts(this.Datevalue)
          this.Datevalue2 = moment(this.Datevalue).format('YYYY')
        }
      } else {
        this.Datevalue2 = moment(this.Datevalue).format('YYYY')
      }
    },
    getDate() {
      this.Datevalue = moment().format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@media only screen and (max-width: 768px) {
  .prevclick{
    left: 5% !important;
    top: 720px !important;
  }

  .nextclick{
    top: 720px !important;
  }

  .My-Card{
    margin-left: 50px;
    margin-right: 50px;
  }

  .swiper-container{
    margin-top: -40px;
  }

  .date-selection-file-upload{
    top: 720px !important;
    margin-right: 70px  !important;
  }

}
</style>
