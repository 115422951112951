<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-list-detail"
    ref="modal-list-detail"
    title="List Detail"
    no-close-on-backdrop
    :hide-footer="true"
    centered
    no-stacking
    size="lg"
  >
    <div>
      <input
        ref="fileInput"
        type="file"
        class="hidden"
        @change="openFiles"
      >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="addFiles"
      >
        <feather-icon
          icon="FilePlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Add New File</span>
      </b-button>
      <div
        class="row"
        style="margin-top: 15px;"
      >
        <div class="col-12">
          <b-card>
            <div class="row">
              <div class="col-12 col-md-8">
                <b-list-group>
                  <vue-perfect-scrollbar>
                    <b-list-group-item
                      v-for="(file, index) in FileList"
                      :key="index"
                      class="d-flex list-group-item-action"
                      :class="{ 'active' : isSelected(index) }"
                      @click="getDataFile(index)"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        style="width: 15px; height: 15px; color: #97FF00; margin-right: 14px;"
                      />
                      <small style="font-size: 0.650rem;">{{ file.Name }}</small>
                    </b-list-group-item>
                  </vue-perfect-scrollbar>
                </b-list-group>
              </div>
              <div
                class="col-12 col-md-4 tab-content"
                style="display: grid; margin-top: 10px;"
              >
                <small style="font-size: 0.650rem; font-weight: bold">Data Founded</small>
                <small style="font-size: 0.650rem; ">{{ filedata.totalData }}</small>
                <small style="font-size: 0.650rem; font-weight: bold">Uploaded By</small>
                <small style="font-size: 0.650rem; ">{{ filedata.User }}</small>
                <small style="font-size: 0.650rem; font-weight: bold">Uploaded On</small>
                <small style="font-size: 0.650rem; ">{{ filedata.CreationDate }}</small>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div
        class="row"
        style="margin-top: -10px;"
      >
        <div class="col-12">
          <b-card>
            <b-link
              class="cursor-pointer"
              @click.prevent="ModalTableListDetail(filedata.totalData)"
            >
              <feather-icon
                icon="EyeIcon"
                size="15"
                style="margin-right: 5px;"
              />
              <small>View Details</small>
            </b-link>
            <div
              class="row"
              style="text-align: center;"
            >
              <div
                class="col-6 col-md-6 col-lg-6"
                style="display: grid;"
              >
                <small>New</small>
                <span class="font-weight-bold">{{ filedata.New }}</span>
                <b-progress
                  :value="calcProgress(filedata.New)"
                  max="100"
                />
              </div>
              <div
                class="col-6 col-md-6 col-lg-6"
                style="display: grid;"
              >
                <small>Pending</small>
                <span class="font-weight-bold">{{ filedata.Pending }}</span>
                <b-progress
                  :value="calcProgress(filedata.Pending)"
                  max="100"
                />
              </div>
              <div
                class="col-6 col-md-6 col-lg-6"
                style="display: grid; margin-top: 10px;"
              >
                <small>Duplicated</small>
                <span class="font-weight-bold">{{ filedata.Duplicated }}</span>
                <b-progress
                  :value="calcProgress(filedata.Duplicated)"
                  max="100"
                />
              </div>
              <div
                class="col-6 col-md-6 col-lg-6"
                style="display: grid; margin-top: 10px;"
              >
                <small>Terminated</small>
                <span class="font-weight-bold">{{ filedata.Terminated }}</span>
                <b-progress
                  :value="calcProgress(filedata.Terminated)"
                  max="100"
                />
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton, BCard, BProgress, BListGroup, BListGroupItem, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import listsService from '@core/services/lists/listsService'
import moment from 'moment'
import MixinFileUpload from '@core/mixins/roaster-importer/fileUpload'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BProgress,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [MixinFileUpload],
  data() {
    return {
      FileList: [],
      filedata: [],
      selected: 0,
      Ipa: {},
      datefileUploaded: '',
    }
  },
  created() {
    this.$bus.$on('setParams', param => {
      this.getFilesCounts(param)
    })
  },
  methods: {
    /* eslint-disable */
    getFilesCounts(param) {
      const { ipa, dateFile } = param
      this.Ipa = ipa
      this.datefileUploaded = dateFile
      listsService.getFilesDateCounts({
        date: dateFile,
        idIpa: ipa.idIpa,
      }).then(response => {
        this.FileList = response.data.individuals
        this.getDataFile(0)
      })
    },
    getDataFile(index) {
      if(this.FileList.length === 0) return
      this.filedata = this.FileList[index]
      this.filedata.totalData = this.filedata.Duplicated + this.filedata.New + this.filedata.Pending + this.filedata.Terminated
      this.selected = index
    },
    isSelected(i) {
      return i === this.selected
    },
    calcProgress(val) {
      return (val * 100) / this.filedata.totalData
    },
    addFiles() {
      this.$refs.fileInput.click();
      // this.$refs['modal-list-detail'].hide()
    },
    openFiles(e){
      let year = moment(this.datefileUploaded).format('YYYY')
      this.CollectFilesAndSend(e.target.files, this.Ipa, year).then(() => {
          this.$refs['modal-list-detail'].hide()
      })
    },
    ModalTableListDetail(idLista) {
      this.$root.$emit('bv::show::modal', 'modal-table-list-detail')
      this.$bus.$emit('setParamsTableListDetail', { ipa: this.Ipa, idlista: idLista, dateFile: this.datefileUploaded})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.ps-container {
  height: 170px;
}
</style>
