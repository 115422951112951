<template>
  <swiper
    ref="mySwiper1"
    class="swiper-centered-slides-importer-2 p-1"
    :options="swiperOptions"
    @select-swiper-index="selectedIndex"
  >
    <swiper-slide
      v-for="(ipaCard, index) in ipainformation"
      :key="index"
    >
      <ipa-date-card
        :ipa="ipaCard"
        :year="Year"
      />
    </swiper-slide>

    <!-- Add Arrows -->
    <div
      slot="button-next"
      ref="btnswiperNext"
      class="swiper-button-next"
      style="display: none;"
    />
    <div
      slot="button-prev"
      ref="btnswiperPrev"
      class="swiper-button-prev"
      style="display: none;"
    />
  </swiper>
</template>

<script>
import {
  Swiper, SwiperSlide,
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import moment from 'moment'
import IpaDateCard from '../roaster-ipa-filezone/IpaDateCard.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    IpaDateCard,
  },
  props: {
    ipainformation: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      Year: '',
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        centeredSlides: true,
        slideToClickedSlide: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        allowTouchMove: false,
      },
    }
  },
  created() {
    this.$parent.$on('next', this.next)
    this.$parent.$on('prev', this.prev)
    this.$parent.$on('select-swiper-index', this.selectedIndex)
  },
  mounted() {
    this.initialState()
  },
  methods: {
    ModalRoasterConfiguration() {
      this.$root.$emit('bv::show::modal', 'modal-roaster-config')
      this.$bus.$emit('setParamsRoasterConfig', { text: 'hi' })
    },
    initialState() {
      const Month = parseInt(moment().format('MM'), 10)
      const Year = moment().format('YYYY')
      this.selectedIndex(Month, Year)
    },
    next() {
      if (this.$refs.btnswiperNext) this.$refs.btnswiperNext.click()
    },
    prev() {
      if (this.$refs.btnswiperPrev) this.$refs.btnswiperPrev.click()
    },
    selectedIndex(month, year) {
      const index = month - 1
      this.Year = year
      this.$refs.mySwiper1.$swiper.slideTo(index, 0)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>
