import listsService from '@core/services/lists/listsService'

const MixinGetIpas = {
  methods: {
    /* eslint-disable no-param-reassign */
    getCounts(Datevalue) {
      listsService.getCounts({
        date: Datevalue,
      }).then(response => {
        this.$store.commit('ipa-roaster-list/setIpas', response.data.counts)
      })
    },
    /* eslint-enable no-param-reassign */
  },
}

export default MixinGetIpas
