<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-roaster-config"
    ref="modal-roaster-config"
    title="Roster Configuration"
    no-close-on-backdrop
    :hide-footer="true"
    centered
    size="xl"
    @hide="refreshData"
  >
    <b-row>
      <b-col cols="12">
        <div>
          <b-overlay
            :show="show"
          >
            <form-wizard
              :color="colorComponent.rgb"
              :title="null"
              :subtitle="null"
              shape="square"
              finish-button-text="Submit"
              back-button-text="Previous"
              class="mb-3"
              style="margin-bottom: 5px !important;"
            >
              <!-- accoint details tab -->
              <tab-content
                title="Roster Configuration File"
                :before-change="validationForm"
              >
                <validation-observer
                  ref="accountRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        File Details
                      </h5>
                      <small class="text-muted">
                        Config Your File for the Analisys Details.
                      </small>
                    </b-col>
                    <b-col
                      cols="12"
                      md="10"
                      class="mb-2"
                      style="justify-content: space-between; display: flex;"
                    >
                      <div>
                        <!-- button show -->
                        <b-button
                          id="popover-selecttemp-show-event"
                          variant="flat-success"
                          @click="show = true"
                        >
                          <feather-icon
                            icon="FolderIcon"
                            class="mr-50"
                          />
                          <small class="align-middle">Select Template</small>
                        </b-button>
                        <b-button
                          id="popover-Savetemp-show-event"
                          ref="showSaveTemplateClick"
                          variant="flat-success"
                          style="visibility: hidden;"
                          @click="show = true"
                        >
                          <feather-icon
                            icon="FolderIcon"
                            class="mr-50"
                          />
                          <small class="align-middle">Select Template</small>
                        </b-button>

                        <!-- popover -->
                        <b-popover
                          ref="popover-show"
                          target="popover-selecttemp-show-event"
                          triggers="click"
                          custom-class="PopOverRoasterConfig"
                          :show.sync="showpopoverSelectTemp"
                        >
                          <b-button
                            class="btn-icon buttonExitPopever"
                            variant="outline-primary"
                            style="float: right; margin-bottom: 10px;"
                            @click="hideoverlay(); hidepopselectTemp()"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                          <template #title>
                            Select your template
                          </template>
                          <b-form-select
                            v-model="ipaTemplateSelectedValue"
                            size="sm"
                          >
                            <option
                              disabled
                              selected
                            >
                              -- Please select an option --
                            </option>
                            <option
                              v-for="(option,index) in optionsTemplate"
                              :key="index"
                              :value="option"
                            >
                              {{ option.Name }}
                            </option>
                          </b-form-select>
                          <b-card
                            no-body
                            class="border-primary"
                            style="margin-top: 15px; margin-bottom: 0px; !important;"
                          >
                            <b-card-body>
                              <ul class="list-unstyled my-1">
                                <li>
                                  <span class="align-middle">{{ ipaTemplateSelectedValue.length === 0 ? `Please Select a template to apply` : `The template ${ipaTemplateSelectedValue.Name} will be assigned to file: ${fileSelected.text}` }}</span>
                                </li>
                                <li>
                                  <span class="align-middle"> Columns {{ ipaTemplateSelectedValue.Columns }} </span>
                                </li>
                                <li class="my-25">
                                  <span class="align-middle">SkipRows {{ ipaTemplateSelectedValue.SkipRows }} </span>
                                </li>
                                <li class="my-25">
                                  <span class="align-middle">SkipRowsUp {{ ipaTemplateSelectedValue.SkipRowsUp }} </span>
                                </li>
                                <li>
                                  <span class="align-middle">Count LastRow: {{ ipaTemplateSelectedValue.LastRow === 1 ? 'Yes' : 'No' }}  </span>
                                </li>
                                <li>
                                  <span class="align-middle">Has Headers: {{ ipaTemplateSelectedValue.Headers === 1 ? 'Yes' : 'No' }} </span>
                                </li>
                              </ul>
                              <b-button
                                variant="primary"
                                block
                                @click="SelectTemplate()"
                              >
                                Select Template
                              </b-button>
                            </b-card-body>
                          </b-card>
                        </b-popover>
                        <b-popover
                          ref="popover-show"
                          target="popover-Savetemp-show-event"
                          triggers="click"
                          custom-class="PopOverRoasterConfig"
                          :show.sync="showpopoverSaveTemp"
                        >
                          <b-button
                            class="btn-icon buttonExitPopever"
                            variant="outline-primary"
                            style="float: right; margin-bottom: 10px;"
                            @click="hideoverlay(); hidepopSaveTemp()"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                          <template #title>
                            Enter a name
                          </template>

                          <b-form-input
                            id="dropdown-form-email"
                            v-model="TemplateName"
                            size="sm"
                            placeholder="Enter Name"
                          />
                          <b-row style="margin-bottom: 15px;">
                            <b-col cols="12">

                              <b-button
                                variant="primary"
                                size="sm"
                                block
                                style="margin-top: 15px;"
                                @click="SaveTemplateConfiguration"
                              >
                                Save Template
                              </b-button>
                            </b-col>
                          </b-row>
                          <small>The configuration that will be saved will be the file you have selected</small>
                        </b-popover>
                      </div>
                      <b-button
                        variant="dark"
                        size="sm"
                        @click="SaveConfiguration"
                      >
                        Save configuration
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="2"
                      class="mb-2"
                      style="padding-right: 0px;"
                    >
                      <b-list-group>
                        <vue-perfect-scrollbar>
                          <b-list-group-item
                            v-for="(file, index) in FileList"
                            :key="index"
                            class="list-group-item-action"
                            :class="{ 'active' : isSelected(index) }"
                            @click="getDataFile(index); setvaluesofFile(index)"
                          >
                            <feather-icon
                              :icon=" !Object.keys(file.template).length ? 'AlertTriangleIcon' : 'CheckIcon' "
                              size="15"
                              style="width: 15px; height: 15px; margin-right: 10px;"
                              :style=" !Object.keys(file.template).length ? 'color: #FF2D00;' : 'color: #85FC21;'"
                            />
                            <small style="font-size: 0.650rem;">{{ file.text }}</small>
                          </b-list-group-item>
                        </vue-perfect-scrollbar>
                      </b-list-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="10"
                      class="mb-2"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          xl="4"
                          md="12"
                          class="d-flex justify-content-between flex-column"
                        >
                          <div>
                            <div class="d-flex flex-column">
                              <span
                                v-if="NameTemp !== ''"
                                style="font-size: 1rem; font-weight: 600; margin-bottom: 15px;"
                              > Nombre:
                                <small style="font-size: 1rem;">{{ NameTemp }}</small>
                              </span>
                              <b-form-group>
                                <b-input-group>
                                  <b-input-group-prepend is-text>
                                    SkipRows
                                  </b-input-group-prepend>
                                  <b-form-spinbutton
                                    v-model="SkipRows"
                                    min="0"
                                    @change="skipRowsExcel"
                                  />
                                </b-input-group>
                              </b-form-group>
                              <b-form-group>
                                <b-input-group>
                                  <b-input-group-prepend is-text>
                                    SkipRowsUp
                                  </b-input-group-prepend>
                                  <b-form-spinbutton
                                    v-model="SkipRowsUp"
                                    min="0"
                                    @change="skipRowsExcel"
                                  />
                                </b-input-group>
                              </b-form-group>
                              <div
                                class="d-flex flex-wrap"
                                style="margin-bottom: 10px;"
                              >
                                <b-form-checkbox
                                  v-model="LastRows"
                                  :class="colorComponent.class"
                                  name="check-button"
                                  switch
                                  style="margin-left: 5px"
                                  @change="skipRowsExcel"
                                >
                                  <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                  </span>
                                  <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                  </span>
                                </b-form-checkbox>
                                <span
                                  style="margin-top: 2px"
                                  class="font-weight-bold"
                                >Count LastRow</span>
                              </div>
                              <div
                                class="d-flex flex-wrap"
                                style="margin-bottom: 10px;"
                              >
                                <b-form-checkbox
                                  v-model="Headers"
                                  :class="colorComponent.class"
                                  name="check-button"
                                  switch
                                  style="margin-left: 5px"
                                >
                                  <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                  </span>
                                  <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                  </span>
                                </b-form-checkbox>
                                <span
                                  style="margin-top: 2px"
                                  class="font-weight-bold"
                                >Headers</span>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <b-table
                            responsive
                            :items="items"
                            class="mb-0"
                            :bordered="true"
                            :small="true"
                            :tbody-tr-class="rowClass"
                          >
                            <template
                              v-slot:head()="data"
                              class="no-padd"
                            >
                              <div
                                class="text-nowrap"
                                style="display:flex"
                              >
                                <b-form-checkbox
                                  v-model="valueSelected2[data.column]"
                                  :class="colorComponent.class"
                                  name="check-button"
                                  style="margin-top: 4px;"
                                  @change="setSelectedColumn($event, data.column, 'isSelected')"
                                />
                                <multiselect
                                  v-model="valueSelected[data.column]"
                                  :options="options"
                                  :searchable="true"
                                  :close-on-select="true"
                                  :show-labels="true"
                                  track-by="value"
                                  label="value"
                                  :max-height="150"
                                  select-label=""
                                  :option-height="30"
                                  style="width: 100%; font-size: 10px; margin-top: 2px;"
                                  @input="setSelectedColumn( $event && $event.value ? $event.value : '', data.column, 'name')"
                                >
                                  <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                  >
                                    <div style="font-size: 10px;">
                                      {{ props.option.value }}
                                    </div>
                                  </template>
                                  <template
                                    slot="option"
                                    slot-scope="props"
                                  >
                                    <div style="padding: 0px !important;">
                                      {{ props.option.value }}
                                    </div>
                                  </template>
                                  <template
                                    slot="noResult"
                                  >
                                    <div style="padding: 0px !important; font-size: 10px;">
                                      No Elements Found
                                    </div>
                                  </template>
                                  <template
                                    slot="placeholder"
                                  >
                                    Select option
                                  </template>
                                </multiselect>
                              </div>
                            </template>

                            <template #cell()="data">
                              <span class="text-nowrap">
                                {{ data.value }}
                              </span>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
            </form-wizard>
          </b-overlay>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BListGroup, BListGroupItem,
  BInputGroup, BInputGroupPrepend,
  BFormSpinbutton,
  BFormCheckbox,
  BTable,
  BButton,
  BFormSelect,
  BOverlay,
  BCard, BCardBody,
  VBPopover, BPopover,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Multiselect from 'vue-multiselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'
import listsService from '@core/services/lists/listsService'
import Swal from 'sweetalert2'
import MixinGetIpas from '@core/mixins/roaster-importer/getIpas'

export default {
  components: {
    BModal,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupPrepend,
    VuePerfectScrollbar,
    BFormSpinbutton,
    BFormCheckbox,
    BTable,
    Multiselect,
    BButton,
    BFormSelect,
    BOverlay,
    BCard,
    BCardBody,
    BPopover,
  },
  directives: {
    'b-modal': VBModal,
    'b-popover': VBPopover,
  },
  mixins: [MixinGetIpas],
  data() {
    return {
      ipaTemplateSelectedValue: [],
      optionsTemplate: [],
      show: false,
      items: [],
      selected: null,
      options: [
        // { value: 'Member Name' },
        { value: 'First Name' },
        // { value: 'Middle Name' },
        { value: 'Last Name' },
        { value: 'PCP Name' },
        // { value: 'Primary Care Provider Name' },
        { value: 'PCP ID' },
        // { value: 'Primary Care Provider ID' },
        { value: 'Member ID' },
        // { value: 'CIN' },
        // { value: 'HP' },
        { value: 'Health Plan' },
        // { value: 'OPT' },
        { value: 'DOB' },
        // { value: 'Date Of Birth' },
        // { value: 'Sex' },
        { value: 'Gender' },
        // { value: 'Sexual Orientation' },
        // { value: 'Language' },
        // { value: 'Member Address' },
        // { value: 'Member City' },
        // { value: 'Member State' },
        // { value: 'Member Phone' },
        // { value: 'Member Zip Code' },
        // { value: 'PCP From Date' },
        // { value: 'PCP Thru Date' },
        // { value: 'Date member has been Added' },
        // { value: 'Date member has been Re-Enrolled' },
        // { value: 'Date member has been PCP Corrected' },
        // { value: 'Date member has been PCP Changed' },
        // { value: 'Date member has been Dropped' },
        // { value: 'Transaction Code' },
        // { value: 'Transaction Description' },
      ],
      showpopoverSelectTemp: false,
      showpopoverSaveTemp: false,
      colorComponent: [],
      FileList: [],
      valueSelected: [],
      valueSelected2: [],
      checkboxSelected: [],
      fileSelected: [],
      indexFileSelected: 0,
      LastRows: false,
      SkipRows: 0,
      SkipRowsUp: 0,
      NameTemp: '',
      Headers: false,
      TemplateName: '',
      idIpa: 0,
      dateFiles: '',
    }
  },
  created() {
    this.$bus.$on('setParamsRoasterConfig', param => {
      const { idIpa, files, dateFiles } = param
      this.dateFiles = dateFiles
      const fileList = []
      files.forEach(file => {
        fileList.push({
          blob_file: file,
          text: file.name.split('.')[0],
          extension: file.name.split('.')[1],
          template: {},
        })
      })
      this.FileList = fileList
      this.idIpa = idIpa
      this.getDataFile(0)
      this.setvaluesofFile(0)
      this.getIpaTemplates(idIpa)
    })
  },
  methods: {
    SelectTemplate() {
      if (!Object.keys(this.ipaTemplateSelectedValue).length <= 0) {
        this.valueSelected = []
        this.valueSelected2 = []
        this.checkboxSelected = []
        Object.entries(this.ipaTemplateSelectedValue.positionCols).forEach(item => {
          this.checkboxSelected[item[0]] = { isSelected: item[1].isSelected, name: item[1].name }
        })
        this.FileList[this.indexFileSelected].template = {
          SkipRows: this.ipaTemplateSelectedValue.SkipRows,
          SkipRowsUp: this.ipaTemplateSelectedValue.SkipRowsUp,
          LastRows: this.ipaTemplateSelectedValue.LastRow === 1,
          Headers: this.ipaTemplateSelectedValue.Headers === 1,
          ConfigColumns: this.checkboxSelected,
          Name: this.ipaTemplateSelectedValue.Name,
          idTemplate: this.ipaTemplateSelectedValue.Id,
        }
        this.checkboxSelected = []
        this.setvaluesofFile(this.indexFileSelected + 1)
        this.getDataFile(this.indexFileSelected + 1)
        this.hideoverlay()
        this.hidepopselectTemp()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'No template has been selected',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    showSaveTemplate() {
      this.show = true
      this.TemplateName = ''
    },
    SaveTemplateConfiguration() {
      if (this.TemplateName !== '') {
        listsService.createIpaTemplate({
          idIpa: this.idIpa,
          skipRows: this.SkipRows,
          skipRowsUp: this.SkipRowsUp,
          lastRows: this.LastRows,
          headers: this.Headers,
          indexColumn: this.checkboxSelected,
          name: this.TemplateName,
        }).then(response => {
          this.getIpaTemplates(this.idIpa)
          Object.entries(response.data.template.positionCols).forEach(item => {
            this.checkboxSelected[item[0]] = { isSelected: item[1].isSelected, name: item[1].name }
          })
          this.FileList[this.indexFileSelected].template = {
            SkipRows: response.data.template.SkipRows,
            SkipRowsUp: response.data.template.SkipRowsUp,
            LastRows: response.data.template.LastRow === 1,
            Headers: response.data.template.Headers.data[0] === 1,
            ConfigColumns: this.checkboxSelected,
            Name: response.data.template.Name,
            idTemplate: response.data.template.Id,
          }
          this.setvaluesofFile(this.indexFileSelected + 1)
          this.getDataFile(this.indexFileSelected + 1)
          this.hideoverlay()
          this.hidepopSaveTemp()
        }).catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '',
                text: error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Network Error',
                text: 'Your network connection is not available',
                icon: 'WifiOffIcon',
                variant: 'danger',
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Introduce a Name to your configuration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    SaveConfiguration() {
      const array2 = []
      let cont = 0
      let cont2 = 0
      const empty = this.checkboxSelected.every(v => v.isSelected === false || v.isSelected === undefined)
      if (!empty) {
        this.checkboxSelected.forEach(index => {
          if (index.isSelected) {
            if (index.name === undefined) {
              cont += 1
            } else if (index.name !== '') {
              array2.push(index.name)
            } else if (index.name === '') {
              cont += 1
            }
          }
        })
        this.checkboxSelected.forEach(index => {
          if (index.name) {
            if (index.isSelected === undefined) {
              cont2 += 1
            } else if (index.isSelected === false) {
              cont2 += 1
            }
          }
        })
        if (cont < 1 && cont2 < 1) {
          const isDuplicate = array2.some((item, idx) => array2.indexOf(item) !== idx)
          if (isDuplicate) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: 'fields are Repeated',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            const validation = this.validationOfFields()
            if (validation) {
              const fileanalyzed = this.FileList[this.indexFileSelected].template.idTemplate
              if (fileanalyzed === undefined) {
                this.$refs.showSaveTemplateClick.click()
              } else {
                Swal.fire({
                  title: 'Attention',
                  text: 'This file already has a configuration. you want to change it',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Apply another Configuration',
                }).then(result => {
                  if (result.isConfirmed) {
                    this.$refs.showSaveTemplateClick.click()
                  }
                })
              }
            }
          }
        } else {
          if (cont >= 1) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: cont > 1 ? `${cont} fields are selected but doesn´t have value` : `${cont} field is selected but doesn´t have value`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else if (cont2 >= 1) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: cont2 > 1 ? `${cont2} fields are without checking` : `${cont2} field is without checking`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          cont = 0
          cont2 = 0
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: `There is no field selected to analyze the file: ${this.fileSelected.text}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    setSelectedColumn(event, index, param) {
      const objColumn = {}
      objColumn[param] = event
      this.checkboxSelected[index] = { ...this.checkboxSelected[index], ...objColumn }
      if (param === 'isSelected') return
      this.setSelectedColumn(true, index, 'isSelected')
      this.valueSelected2[index] = true
    },
    hideoverlay() {
      this.show = !this.show
      this.ipaTemplateSelectedValue = []
    },
    hidepopselectTemp() {
      this.showpopoverSelectTemp = !this.showpopoverSelectTemp
    },
    hidepopSaveTemp() {
      this.showpopoverSaveTemp = !this.showpopoverSaveTemp
    },
    readFile(file) {
      const { blob_file, extension } = file
      if (extension === 'xlsx' || extension === 'xls' || extension === 'csv') {
        const reader = new FileReader()
        reader.onload = e => {
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          const { range, rangeLastRow } = this.getRangeFromString(ws['!ref'])
          const optionsExcelData = {
            rangeText: range,
            lastRowTxt: this.LastRows ? rangeLastRow : null,
            workBook: ws,
          }
          const data = this.getDataFromExcel(optionsExcelData)
          /* Convert array of arrays */
          // const data = XLSX.utils.sheet_to_json(ws, { header: 1, range: rangeText, defval: '' })
          this.items = data
        }
        reader.readAsBinaryString(blob_file)
      } else {
        this.items = []
      }
    },
    styleRow(index) {
      if (index === 0 && this.Headers) return 'font-weight-bold text-success'
      if (index === this.items.length - 1 && this.LastRows) return 'font-weight-bold last-row'
      return ''
    },
    rowClass(item) {
      const index = this.items.indexOf(item)
      if (index === 0 && this.Headers) return 'font-weight-bold text-success'
      if (index === this.items.length - 1 && this.LastRows) return 'font-weight-bold last-row'
      return ''
    },
    skipRowsExcel() {
      this.getDataFile(this.indexFileSelected)
    },
    getDataFromExcel(optionsExcelData) {
      const { rangeText, lastRowTxt, workBook } = optionsExcelData
      let excelData = []
      excelData = XLSX.utils.sheet_to_json(workBook, { header: 1, range: rangeText, defval: '' })
      if (lastRowTxt) {
        const excelLastRow = XLSX.utils.sheet_to_json(workBook, { header: 1, range: lastRowTxt, defval: '' })
        excelData.push(excelLastRow[0])
      }
      return excelData
    },
    getRangeFromString(rangeText) { // @range String: 'A1:s103' @lastRows boolean
      const [numberFirstTxt, numberSecondTxt] = rangeText.split(':')
      const firstLetter = numberFirstTxt.replace(/[^A-Za-z]/g, '')
      const secondLetter = numberSecondTxt.replace(/[^A-Za-z]/g, '') // Get last row of excel
      const range = `${firstLetter}${this.SkipRows + 1}:${secondLetter}${this.SkipRows + 10}`
      const lastRowNumber = numberSecondTxt.replace(/[^\d.-]/g, '')
      const rangeLastRow = `${firstLetter}${lastRowNumber - this.SkipRowsUp}:${secondLetter}${lastRowNumber - this.SkipRowsUp}`
      return { range, rangeLastRow }
    },
    BindColorComponents(status) {
      if (status === 'pdf') {
        this.colorComponent = { rgb: '#FF2D00', class: 'custom-control-danger' }
      } else if (status === 'xlsx' || status === 'xls' || status === 'csv') {
        this.colorComponent = { rgb: '#009843', class: 'custom-control-success' }
      } else if (status === 'txt') {
        this.colorComponent = { rgb: '#999999', class: 'custom-control-secondary' }
      }
    },
    /* eslint-disable */
    getDataFile(index) {
      if (index >= this.FileList.length) {
        index = 0
      }
      const file = this.FileList[index]
      this.fileSelected = file
      this.selected = index
      this.indexFileSelected = index
      this.BindColorComponents(file.extension)
      this.readFile(file)
    },
    /* eslint-enable */
    isSelected(i) {
      return i === this.selected
    },
    /* eslint-disable */
    setvaluesofFile(index) {
      if (index >= this.FileList.length) {
        index = 0
      }
      let template = this.FileList[index].template
      if (!Object.keys(template).length <= 0) {
        this.valueSelected = []
        this.valueSelected2 = []
        this.checkboxSelected = []
        this.SkipRows = template.SkipRows
        this.SkipRowsUp = template.SkipRowsUp
        this.LastRows = template.LastRows
        this.Headers = template.Headers
        this.NameTemp = template.Name
        template.ConfigColumns.forEach((item, i) => {
          this.valueSelected2[i] = item.isSelected
          this.valueSelected[i] = { value: item.name }
          this.checkboxSelected[i] = { isSelected: item.isSelected, name: item.name }
        })
      } else {
        this.SkipRows = 0
        this.SkipRowsUp = 0
        this.LastRows = false
        this.Headers = false
        this.valueSelected = []
        this.valueSelected2 = []
        this.checkboxSelected = []
        this.NameTemp = ''
      }
    },
    /* eslint-enable */
    /* eslint-disable */
    formSubmitted() {
      return
    },
    /* eslint-enable */
    validationForm(e) {
      if (e) e.preventDefault()
      const Oneempty = this.FileList.every(v => Object.keys(v.template).length)
      if (!Oneempty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'One of the files are not configured',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        Swal.fire({
          title: 'Send files',
          text: '¿You want to send the files configured in this way?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Send for analysis',
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const formData = new FormData()
            formData.append('idIpa', this.idIpa)
            formData.append('dateInFile', this.dateFiles)
            this.FileList.forEach(file => {
              formData.append('files', file.blob_file)
              formData.append('arrDetails', JSON.stringify(file.template))
            })
            listsService.uploadFile(formData).then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Succes',
                  text: 'File successfuly analyzed',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
              // TODO: OPEN MODAL FOR ANALYTICS
              this.$root.$emit('bv::show::modal', 'modal-list-analitics')
              this.$bus.$emit('setParams', { analitycs: response.data.results })
              this.show = false
              this.refreshData()

              // this.getCounts(datefileuploaded)
              // resolve()
            })
          }
        })
      }
    },
    validationOfFields() {
      let Text = ''
      const MemberName = this.checkboxSelected.some(item => item.name === 'Member Name')
      const FirstName = this.checkboxSelected.some(item => item.name === 'First Name')
      const MiddleName = this.checkboxSelected.some(item => item.name === 'Middle Name')
      const LastName = this.checkboxSelected.some(item => item.name === 'Last Name')
      const Dob = this.checkboxSelected.some(item => item.name === 'DOB')
      // First Name
      /* eslint-disable */
      if (MemberName && FirstName) {
        Text += `- Configuration have Member Name and First Name at same time<br>`
      } else {
        Text += ''
      }
      // Last Name
      if (LastName && !FirstName) {
        Text += `- Last Name need First Name <br>`
      } else {
        Text += ''
      }
      if (LastName && MemberName) {
        Text += `- Configuration have Member Name and Last Name at same time <br>` 
      } else {
        Text += ''
      }
      // Middle Name
      if (MiddleName && !FirstName) {
        Text += `- Middle Name need First Name <br> `
      } else {
        Text += ''
      }
      if (MiddleName && MemberName) {
        Text += `- Configuration have Member Name and Middle Name at same time <br>`
      } else {
        Text += ''
      }
      if (!Dob) {
        Text += `- Configuration need DOB <br>`
      } else {
        Text += ''
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Column Definition Error',
          html: '<div class="lbedo">' + Text + '</div>',
          showConfirmButton: false,
        })
        return false
      }
      return true
      /* eslint-enable */
    },
    refreshData() {
      this.$store.commit('ipa-roaster-list/setIpas', [])
      this.getCounts(this.dateFiles)
      this.$refs['modal-roaster-config'].hide()
      this.$root.$emit('bv::hide::modal', 'modal-roaster-config')
    },
    getIpaTemplates(idIpa) {
      listsService.getIpaTemplates({
        idIpa,
      }).then(response => {
        this.optionsTemplate = response.data.data
      })
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
</style>

<style lang="scss" scoped>
.ps-container {
  height: 300px;
}

.no-padd{
  padding: 0px !important;
}
</style>

<style>
.multiselect{
  min-height: 25px;
}
.multiselect__select{
  margin-top: -7px;
  padding: 16px 20px;
}
.multiselect__tags{
  height: 15px;
  padding: 8px 40px 10px 8px;
  padding-bottom: 0px;
  padding-top: 0px !important;
  min-height: 25px;
}
.multiselect__single{
  margin-bottom: 0px;
  margin-top: 2px;
  padding-right: 50px;
}
.multiselect__placeholder{
  margin-bottom: 0px;
  text-transform: none;
  font-size: 10px;
}
.multiselect__input{
  margin-top: 2px;
  font-size: 10px;
  width: 85px !important;
}
.multiselect__content-wrapper{
  width: 255px !important;
}
.DropdownRoasterConfig{
  z-index: 12;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.PopOverRoasterConfig{
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-width: 410px !important;
  width: 410px !important;
}
.last-row{
  background-color: #f3f2f7;
}
.buttonExitPopever{
  margin-bottom: 10px;
  position: absolute;
  margin-top: -56px;
  background-color: white;
  margin-left: 370px;
}
.buttonExitPopever:hover{
  background-color: white !important;
}
.format-pre pre {
  background: #49483e;
  color: #f7f7f7;
  padding: 10px;
  font-size: 14px;
}
.lbedo{
  text-align: left;
}
</style>
