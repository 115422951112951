<template>
  <fragment>
    <div
      class="swiper-text"
      style="margin-bottom: 10px;"
    >
      {{ ipa.month_name }} {{ year }}
    </div>
    <drop-zone
      :class-drop-zone="swiperModClass"
      :icon-drop-zone="ipa.fileNum > 0 ? 'CheckIcon' : 'UploadCloudIcon'"
      :loading="loading"
      :on-click="onClickDropZone"
      :on-drag-end="onDragLeave_End"
      :on-drag-enter="onDragOver_Enter"
      :on-drag-leave="onDragLeave_End"
      :on-drag-over="onDragOver_Enter"
      :on-drop="onDrop"
      :text-content="ipa.fileNum > 0 ? `${ipa.fileNum}` : 'UpLoad ...'"
    >
      <template
        slot="content"
      >
        <div
          class="row inforoaster"
          style="padding: 20px; padding-top: 0px; padding-bottom: 0px;"
        >
          <div
            class="col-3"
            style="display: grid;"
          >
            <small style="font-size: 0.650rem;">New</small>
            <small style="font-size: 0.650rem; font-weight: bold">{{ ipa.new }}</small>
          </div>
          <div
            class="col-3"
            style="display: grid;"
          >
            <small style="font-size: 0.650rem;">Pending</small>
            <small style="font-size: 0.650rem; font-weight: bold">{{ ipa.pending }}</small>
          </div>
          <div
            class="col-3"
            style="display: grid;"
          >
            <small style="font-size: 0.650rem;">Duplicated</small>
            <small style="font-size: 0.650rem; font-weight: bold">{{ ipa.duplicated }}</small>
          </div>
          <div
            class="col-3"
            style="display: grid;"
          >
            <small style="font-size: 0.650rem;">Terminated</small>
            <small style="font-size: 0.650rem; font-weight: bold">{{ ipa.terminated }}</small>
          </div>
        </div>
      </template>
    </drop-zone></fragment>
</template>

<script>
import MixinFileUpload from '@core/mixins/roaster-importer/fileUpload'
import DropZone from '../dropzone/DropZone.vue'

export default {
  components: {
    DropZone,
  },
  mixins: [MixinFileUpload],
  props: {
    ipa: {
      type: Object,
      required: true,
    },
    year: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      swiperModClass: '',
      loading: false,
    }
  },
  methods: {
    onClickDropZone() {
      if (this.ipa.fileNum < 1) {
        const input = document.createElement('input')
        input.type = 'file'
        input.setAttribute('multiple', '')
        input.onchange = () => {
          this.loading = true
          this.CollectFilesAndSend(input.files, this.ipa, this.year).then(() => {
            this.loading = false
          })
        }
        input.click()
        this.swiperModClass = ''
      } else {
        this.ModalListDetail(this.ipa, this.year)
      }
    },
    onDrop(e) {
      if (this.ipa.fileNum < 1) {
        this.loading = true
        this.CollectFilesAndSend(e.dataTransfer.files, this.ipa, this.year).then(() => {
          this.loading = false
          this.swiperModClass = ''
        })
      } else {
        this.ModalListDetail(this.ipa, this.year)
        this.swiperModClass = ''
      }
      e.preventDefault()
      e.stopPropagation()
    },
    onDragOver_Enter(e) {
      this.swiperModClass = 'mydesign'
      e.preventDefault()
      e.stopPropagation()
    },
    onDragLeave_End(e) {
      this.swiperModClass = ''
      e.preventDefault()
      e.stopPropagation()
    },
  },
}
</script>
