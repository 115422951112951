import Swal from 'sweetalert2'
// import listsService from '@core/services/lists/listsService'
import moment from 'moment'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MixinGetIpas from '@core/mixins/roaster-importer/getIpas'

const MixinFileUpload = {
  mixins: [MixinGetIpas],
  methods: {
    ModalListDetail(ipaCard, year) {
      const datefileuploaded = moment(`${year}-${ipaCard.month_name}-01`).format('YYYY-MM-DD')
      this.$root.$emit('bv::show::modal', 'modal-list-detail')
      this.$bus.$emit('setParams', { dateFile: datefileuploaded, ipa: ipaCard })
    },
    ModalRoasterConfiguration(formdata) {
      this.$root.$emit('bv::show::modal', 'modal-roaster-config')
      this.$bus.$emit('setParamsRoasterConfig', formdata)
    },
    /* eslint-disable no-param-reassign */
    CollectFilesAndSend(filesArray, ipacard, year) {
      return new Promise(resolve => {
        const datefileuploaded = moment(`${year}-${ipacard.month_name}-01`).format('YYYY-MM-DD')
        const files = Array.from(filesArray)
        let FileNames = ''
        // const formData = new FormData()
        files.forEach(file => {
          FileNames += `${file.name}, `
          // formData.append('files', file)
        })
        const filesInformation = {
          files,
          dateFiles: datefileuploaded,
          idIpa: ipacard.idIpa,
        }
        // formData.append('dateInFile', datefileuploaded)
        // formData.append('idIpa', )
        Swal.fire({
          title: 'Upload File',
          text: `Do you want to insert the next File ${FileNames}`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, Analyze the file!',
        }).then(result => {
          if (result.isConfirmed) {
            this.ModalRoasterConfiguration(filesInformation)
            /* if (this.swiperModClass) this.swiperModClass = ''
            try {
              listsService.uploadFile(formData).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Succes',
                    text: `${response.data.status}`,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                })
                this.getCounts(datefileuploaded)
                resolve()
              }).catch(error => {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Logged In Error',
                      text: error.response.data.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Network Error',
                      text: 'Your network connection is not available',
                      icon: 'WifiOffIcon',
                      variant: 'danger',
                    },
                  })
                } else {
                  // Something happened in setting up the request that triggered an Error
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Somethig went wrong',
                      text: 'Try Again later',
                      icon: 'WifiOffIcon',
                      variant: 'danger',
                    },
                  })
                }
                resolve()
              })
            } catch (error) {
              resolve()
            }
            FileNames = '' */
          } else {
            FileNames = ''
            resolve()
          }
        })
      })
    },
    /* eslint-enable no-param-reassign */
  },
}

export default MixinFileUpload
