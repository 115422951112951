<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-list-analitics"
    ref="modal-list-analitics"
    title="List Analitycs"
    no-close-on-backdrop
    :hide-footer="true"
    centered
    no-stacking
    size="lg"
  >
    <div>
      <b-row>
        <b-col
          lg="6"
          sm="6"
        >
          <statistic-card-horizontal
            icon="HashIcon"
            color="warning"
            :statistic="total_rows"
            statistic-title="Total Records"
          />
        </b-col>
        <b-col
          lg="6"
          sm="6"
        >
          <statistic-card-horizontal
            icon="PackageIcon"
            color="warning"
            :statistic="total_records_from_list"
            statistic-title="Records not duplicated"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          sm="6"
        >
          <card-statistic-bar-chart
            :data="hps"
            statistic-title="Health Plans"
          />
        </b-col>
        <b-col
          lg="6"
          sm="6"
        >
          <card-statistic-bar-chart
            :data="pcps"
            statistic-title="Primary Care Providers"
          />
        </b-col>

      </b-row>
      <b-row>
        <b-col
          lg="6"
          sm="6"
        >
          <card-statistic-bar-chart
            :data="ages"
            statistic-title="Ages"
          />
        </b-col>
        <b-col
          lg="6"
          sm="6"
        >
          <card-statistic-bar-chart
            :data="genders"
            statistic-title="Genders"
          />
        </b-col>
      </b-row>

    </div>
  </b-modal>
</template>
<script>
import {
  BModal, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import CardStatisticBarChart from '@core/components/modals/lists/CardStatisticBarChart.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    StatisticCardHorizontal,
    CardStatisticBarChart,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      hps: 0,
      pcps: 0,
      ages: 0,
      genders: 0,
      total_rows: 0,
      total_records_from_list: 0,
    }
  },
  created() {
    this.$bus.$on('setParams', param => {
      console.log(param)
      this.getAnalitycs(param.analitycs)
    })
  },
  methods: {
    /* eslint-disable */
      getAnalitycs(param) {
        const { hps, pcps, ages, genres, total_rows, total_records_from_list } = param
        this.total_rows = total_rows
        this.total_records_from_list = total_records_from_list // total with no duplicates
        this.hps = this.processObjectToChart(hps)
        this.pcps = this.processObjectToChart(pcps)
        this.ages = this.processObjectToChart(ages)
        this.genders = this.processObjectToChart(genres)
      },
      processObjectToChart(data){
        const labels = Object.keys(data);
        const totals = Object.values(data);
        const grandTotal = Object.values(data).reduce((a, b) => a + b);
        return { labels, totals }
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  
  .ps-container {
    height: 170px;
  }
  </style>
  