<template>
  <b-card
    body-class="pb-50"
  >
    <h6> {{ statisticTitle }}</h6>
    <!-- chart -->
    <vue-apex-charts
      height="75"
      :options="statisticsOrder.chartOptions"
      :series="statisticsOrder.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statisticsOrder: {
        series: [
          {
            name: 'Profiles',
            data: [45, 85],
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -10,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '12%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: true,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            categories: ['F', 'M'],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: true,
            },
          },
        },
      },
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.updateChart()
      },
    },
  },
  methods: {
    updateChart() {
      this.statisticsOrder.series = [
        {
          name: this.title,
          data: this.data.totals,
        },
      ]

      this.statisticsOrder.chartOptions.xaxis.categories = this.data.labels
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
        console.log('rezise')
      }, 1000)
    },
  },
}
</script>
