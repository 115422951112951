<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-table-list-detail"
    ref="modal-table-list-detail"
    title="Detail File Users"
    no-close-on-backdrop
    :hide-footer="true"
    centered
    no-stacking
    size="lg"
  >
    <!-- Table Container Card -->
    <div style="margin-bottom: 15px;">
      <b-button
        variant="primary"
        class="btn-icon"
        @click.prevent="goBack"
      >
        <feather-icon icon="ArrowLeftIcon" />
      </b-button>
    </div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="PageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="handleSearch"
              />
              <v-select
                v-model="TypeProfileValue"
                :options="TypeofProfile"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="tableProfiles"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        responsive
        @sort-changed="onSortChange"
      >
        <!-- Column: AccountNumber -->
        <template #cell(account_number)="data">
          <b-link
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: Patient -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                class="border"
                size="32"
                :class="data.item.profile_type.data[0] === 1 ? 'BAvatarPatientProfiles' : 'BAvatarPatientLeads' "
                :text="avatarText(`${data.value} ${data.item.lastname}`)"
                :to="{ name: 'kardex-profile', params: { idPatient: data.item.id } }"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ `${data.value} ${data.item.lastname}` }}
            </span>
            <small class="text-muted">{{ data.item.profile_type.data[0] === 1 ? 'Profile' : 'Lead' }}</small>
          </b-media>
        </template>

        <!-- Column: DOB -->
        <template #cell(dob)="data">
          <span class="text-nowrap">
            {{ data.value | date-format }}
          </span>
        </template>

        <!-- Column: PhoneNumber -->
        <template #cell(phone)="data">
          <span class="text-nowrap">
            {{ data.value | phone-format }}
          </span>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :current-page="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BRow, BCol, BFormInput, BPagination, BTable, BLink, BMedia, BAvatar, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    vSelect,
    BTable,
    BLink,
    BMedia,
    BAvatar,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      fields: [{ key: 'account_number', label: '#', sortable: true }, { key: 'name', label: 'Patient Name', sortable: true }, { key: 'dob', sortable: true }, { key: 'phone', label: 'Phone Number', sortable: true }, { key: 'program', label: 'Coverage', sortable: true }, { key: 'name_source', label: 'Patient Source', sortable: true }, { key: 'enrollementType', sortable: true }],
      items: [],
      TypeProfileValue: '',
      TypeofProfile: ['News', 'Duplicated', 'Pending', 'Terminated'],
      type: '',
      perPage: 10,
      PageOptions: [10, 20],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      avatarText,
      idIpa: 0,
      ipa: {},
      idLista: 0,
      datefileuploaded: '',
    }
  },
  watch: {
  },
  created() {
    /* eslint-disable */
    this.$bus.$on('setParamsTableListDetail', param => {
      const { ipa, idlista, dateFile } = param
      this.ipa = ipa 
      this.idLista = idlista
      this.datefileuploaded = dateFile
    })
  },
  methods: {
    handleSearch(searching) {
      console.log(searching)
    },
    handleChangePage(page) {
      console.log(page)
    },
    onSortChange(params) {
      console.log(params)
    },
    goBack() {
      this.$root.$emit('bv::show::modal', 'modal-list-detail')
      this.$bus.$emit('setParams', { dateFile: this.datefileuploaded, ipa: this.ipa })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
