<template>
  <div
    class="rounded swiper-shadow swiper-mod"
    :class="classDropZone"
    style="padding-top: 10px; padding-bottom: 20px; width: 100%;"
    @click.prevent="onClick"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @dragend="onDragEnd"
    @dragover="onDragOver"
    @drop="onDrop"
  >
    <b-button
      v-if="loading"
      variant="primary"
      disabled
      style="width: 290px; margin-top: -12px; margin-bottom: -22px; height: 125px; background-color: transparent !important; color: #262262 !important; border: none;"
    >
      <b-spinner small />
      Loading...
    </b-button>
    <div v-if="!loading">
      <feather-icon
        :icon="iconDropZone"
        size="20"
      />
      <div
        class="swiper-text pt-md-1 pt-sm-50"
        style="padding-top: 5px; padding-bottom: 10px "
      >
        {{ textContent }}
      </div>
    </div>
    <slot
      v-if="!loading"
      name="content"
    />
  </div>
</template>

<script>
import { BSpinner, BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    onDragEnter: {
      type: Function,
      required: true,
    },
    onDragLeave: {
      type: Function,
      required: true,
    },
    onDragEnd: {
      type: Function,
      required: true,
    },
    onDragOver: {
      type: Function,
      required: true,
    },
    onDrop: {
      type: Function,
      required: true,
    },
    iconDropZone: {
      type: String,
      required: true,
    },
    textContent: {
      type: String,
      required: true,
    },
    classDropZone: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
